import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../../actions';

// import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  render() {
    const { game } = this.props;
    let players = game.data.groups || [];
    players = game.data.players.map((p) => {
      // Accepted Candidates
      let candidatesThatAccepted = p.results.filter(function (candidate) {
        return candidate.final_offer >= candidate.candidate.threshold;
      });

      // AVG Waste Calc
      let avgWaste = 0;
      if (candidatesThatAccepted.length !== 0) {
        candidatesThatAccepted.forEach(function (candidate) {
          if (candidate.final_offer - candidate.candidate.threshold > 0)
            avgWaste += candidate.final_offer - candidate.candidate.threshold;
        });
      }

      // AVG Offer
      let avgOffer = 0;
      p.results.forEach(function (candidate) {
        avgOffer += Math.round(candidate.final_offer);
      });

      return {
        name: p.name,
        lastname: p.lastname,
        email: p.email,
        candidatesThatAccepted: candidatesThatAccepted,
        acceptRate: {
          label: numeral(
            candidatesThatAccepted.length / p.results.length
          ).format('0,0%'),
          value:
            p.results.length > 0
              ? candidatesThatAccepted.length / p.results.length
              : 0,
        },
        avgWaste: {
          label: numeral(avgWaste / candidatesThatAccepted.length).format(
            '$0,0'
          ),
          value:
            candidatesThatAccepted.length > 0
              ? avgWaste / candidatesThatAccepted.length
              : 0,
        },
        avgOffer: {
          label: numeral(avgOffer / p.results.length).format('$0,0'),
          value: avgOffer,
        },
      };
      // p.candidatesThatAccepted = p.results.filter(function (candidate) {
      //   return candidate.final_offer >= candidate.candidate.threshold;
      // });
      // p.acceptRate = numeral(
      //   p.candidatesThatAccepted.length / p.results.length
      // ).format('0,0%');
      // p.avgOffer = 0;
      // p.results.forEach(function (candidate) {
      //   p.avgOffer += Math.round(candidate.final_offer);
      // });
      // p.avgOffer = numeral(p.avgOffer / p.results.length).format('$0,0');
      // return p;
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Name',
              'Surname',
              'Email',
              'Acceptance Rate (%)',
              'Average Offer ($)',
              'Average Overpayment ($)',
            ]}
            fields={[
              'name',
              'lastname',
              'email',
              'acceptRate',
              'avgOffer',
              'avgWaste',
            ]}
            sortables={[
              'name',
              'lastname',
              'email',
              'acceptRate',
              'avgOffer',
              'avgWaste',
            ]}
            data={players}
            onRowClick={this.rowClick}
          />
        </div>

        {/* <Modal
          isOpen={this.state.group ? true : false}
          centered={true}
          size='xxl'
          toggle={() => this.setState({ group: null })}>
          <ModalBody className='text-center'>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size='2x'
              className='icon bg-white text-danger'
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={() => this.setState({ group: null })}
            />
            {this.state.group && <GroupDebrief group={this.state.group} />}
          </ModalBody>
        </Modal> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
