import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class DebriefSalary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sf: false,
      chicago: false,
      miami: false,
    };
  }

  render() {
    const { sf, chicago, miami } = this.state;
    const { game } = this.props;

    if (!game.data) return null;

    const city_sf = [];
    const city_sf_avg_salary_men = [];
    const city_sf_avg_change_men = [];
    const city_sf_avg_salary_women = [];
    const city_sf_avg_change_women = [];

    const city_chicago = [];
    const city_chicago_avg_salary_men = [];
    const city_chicago_avg_change_men = [];
    const city_chicago_avg_salary_women = [];
    const city_chicago_avg_change_women = [];

    const city_miami = [];
    const city_miami_avg_salary_men = [];
    const city_miami_avg_change_men = [];
    const city_miami_avg_salary_women = [];
    const city_miami_avg_change_women = [];

    game.data.players.forEach((p) => {
      p.results.forEach((r) => {
        if (r.city === 'San Francisco') {
          city_sf.push(r);

          if (r.candidate.genre === 'male')
            city_sf_avg_salary_men.push(r.final_offer);
          if (r.candidate.genre === 'female')
            city_sf_avg_salary_women.push(r.final_offer);

          if (r.candidate.genre === 'male')
            city_sf_avg_change_men.push(r.final_offer - r.first_offer);
          if (r.candidate.genre === 'female')
            city_sf_avg_change_women.push(r.final_offer - r.first_offer);
        }

        if (r.city === 'Chicago') {
          city_chicago.push(r);

          if (r.candidate.genre === 'male')
            city_chicago_avg_salary_men.push(r.final_offer);
          if (r.candidate.genre === 'female')
            city_chicago_avg_salary_women.push(r.final_offer);

          if (r.candidate.genre === 'male')
            city_chicago_avg_change_men.push(r.final_offer - r.first_offer);
          if (r.candidate.genre === 'female')
            city_chicago_avg_change_women.push(r.final_offer - r.first_offer);
        }

        if (r.city === 'Miami') {
          city_miami.push(r);

          if (r.candidate.genre === 'male')
            city_miami_avg_salary_men.push(r.final_offer);
          if (r.candidate.genre === 'female')
            city_miami_avg_salary_women.push(r.final_offer);

          if (r.candidate.genre === 'male')
            city_miami_avg_change_men.push(r.final_offer - r.first_offer);
          if (r.candidate.genre === 'female')
            city_miami_avg_change_women.push(r.final_offer - r.first_offer);
        }
      });
    });

    // Calculate AVGs
    const sf_avg_salary_men =
      city_sf_avg_salary_men.length > 0
        ? city_sf_avg_salary_men.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_sf_avg_salary_men.length
        : '';
    const sf_avg_salary_women =
      city_sf_avg_salary_women.length > 0
        ? city_sf_avg_salary_women.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_sf_avg_salary_women.length
        : '';
    const sf_avg_change_men =
      city_sf_avg_change_men.length > 0
        ? city_sf_avg_change_men.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_sf_avg_change_men.length
        : '';
    const sf_avg_change_women =
      city_sf_avg_change_women.length > 0
        ? city_sf_avg_change_women.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_sf_avg_change_women.length
        : '';

    const chicago_avg_salary_men =
      city_chicago_avg_salary_men.length > 0
        ? city_chicago_avg_salary_men.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_chicago_avg_salary_men.length
        : '';
    const chicago_avg_salary_women =
      city_chicago_avg_salary_women.length > 0
        ? city_chicago_avg_salary_women.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_chicago_avg_salary_women.length
        : '';
    const chicago_avg_change_men =
      city_chicago_avg_change_men.length > 0
        ? city_chicago_avg_change_men.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_chicago_avg_change_men.length
        : '';
    const chicago_avg_change_women =
      city_chicago_avg_change_women.length > 0
        ? city_chicago_avg_change_women.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_chicago_avg_change_women.length
        : '';

    const miami_avg_salary_men =
      city_miami_avg_salary_men.length > 0
        ? city_miami_avg_salary_men.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_miami_avg_salary_men.length
        : '';
    const miami_avg_salary_women =
      city_miami_avg_salary_women.length > 0
        ? city_miami_avg_salary_women.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_miami_avg_salary_women.length
        : '';
    const miami_avg_change_men =
      city_miami_avg_change_men.length > 0
        ? city_miami_avg_change_men.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_miami_avg_change_men.length
        : '';
    const miami_avg_change_women =
      city_miami_avg_change_women.length > 0
        ? city_miami_avg_change_women.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) / city_miami_avg_change_women.length
        : '';

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Salary
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th></th>
                <th className='text-center'>City</th>
                <th className='text-center'>
                  # Discriminatory Firms
                  <span
                    className='glyphicon glyphicon-info-sign'
                    aria-hidden='true'
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title='Alternative offers are drawn at random from a range that corresponds with the candidate’s role. Discriminatory offers are selected from the higher end of each corresponding range.'></span>
                </th>
                <th className='text-center'>Avg. Salary (men)</th>
                <th className='text-center'>Avg. Salary (women)</th>
                <th className='text-center'>Avg. Offer Change (men)</th>
                <th className='text-center'>Avg. Offer Change (women)</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-center'>
                <td className='text-center'>
                  <button
                    className='btn btn-sm btn-outline-primary toggle'
                    onClick={() => this.setState({ sf: !sf })}>
                    {sf ? 'Hide' : 'Show'}
                  </button>
                </td>
                <td>San Francisco</td>
                <td className='el-sf hidex'>
                  {sf && game.data.discriminatory[0]}
                </td>
                <td className='el-sf hidex'>
                  {(sf && numeral(sf_avg_salary_men).format('$0,0')) || '-'}
                </td>
                <td className='el-sf hidex'>
                  {(sf && numeral(sf_avg_salary_women).format('$0,0')) || '-'}
                </td>
                <td className='el-sf hidex'>
                  {(sf && numeral(sf_avg_change_men).format('$0,0')) || '-'}
                </td>
                <td className='el-sf hidex'>
                  {(sf && numeral(sf_avg_change_women).format('$0,0')) || '-'}
                </td>
              </tr>
              <tr className='text-center'>
                <td className='text-center'>
                  <button
                    className='btn btn-sm btn-outline-primary toggle'
                    onClick={() => this.setState({ chicago: !chicago })}>
                    {chicago ? 'Hide' : 'Show'}
                  </button>
                </td>
                <td>Chicago</td>
                <td className='el-chicago hidex'>
                  {chicago && game.data.discriminatory[1]}
                </td>
                <td className='el-chicago hidex'>
                  {(chicago &&
                    numeral(chicago_avg_salary_men).format('$0,0')) ||
                    '-'}
                </td>
                <td className='el-chicago hidex'>
                  {(chicago &&
                    numeral(chicago_avg_salary_women).format('$0,0')) ||
                    '-'}
                </td>
                <td className='el-chicago hidex'>
                  {(chicago &&
                    numeral(chicago_avg_change_men).format('$0,0')) ||
                    '-'}
                </td>
                <td className='el-chicago hidex'>
                  {(chicago &&
                    numeral(chicago_avg_change_women).format('$0,0')) ||
                    '-'}
                </td>
              </tr>
              <tr className='text-center'>
                <td className='text-center'>
                  <button
                    className='btn btn-sm btn-outline-primary toggle'
                    onClick={() => this.setState({ miami: !miami })}>
                    {miami ? 'Hide' : 'Show'}
                  </button>
                </td>
                <td>Miami</td>
                <td className='el-miami hidex'>
                  {miami && game.data.discriminatory[2]}
                </td>
                <td className='el-miami hidex'>
                  {(miami && numeral(miami_avg_salary_men).format('$0,0')) ||
                    '-'}
                </td>
                <td className='el-miami hidex'>
                  {(miami && numeral(miami_avg_salary_women).format('$0,0')) ||
                    '-'}
                </td>
                <td className='el-miami hidex'>
                  {(miami && numeral(miami_avg_change_men).format('$0,0')) ||
                    '-'}
                </td>
                <td className='el-miami hidex'>
                  {(miami && numeral(miami_avg_change_women).format('$0,0')) ||
                    '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DebriefSalary);
