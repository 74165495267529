import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import numeral from 'numeral';

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class DebriefWaste extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sf: true,
      chicago: true,
      miami: true,
    };
  }

  render() {
    const { game } = this.props;

    if (!game.data) return null;

    let wasteSanFran = 0;
    let wasteChicago = 0;
    let wasteMiami = 0;
    let candidatesSanFran = 0;
    let candidatesChicago = 0;
    let candidatesMiami = 0;

    game.data.players.forEach(function (player) {
      if (player.results.length === 18) {
        player.results.forEach(function (candidate) {
          if (candidate.final_offer >= candidate.candidate.threshold) {
            // candidate accepted offer
            if (candidate.city === 'San Francisco') {
              candidatesSanFran++;
              if (candidate.final_offer - candidate.candidate.threshold > 0)
                wasteSanFran +=
                  candidate.final_offer - candidate.candidate.threshold;
            }
            if (candidate.city === 'Chicago') {
              candidatesChicago++;
              if (candidate.final_offer - candidate.candidate.threshold > 0)
                wasteChicago +=
                  candidate.final_offer - candidate.candidate.threshold;
            }
            if (candidate.city === 'Miami') {
              candidatesMiami++;
              if (candidate.final_offer - candidate.candidate.threshold > 0)
                wasteMiami +=
                  candidate.final_offer - candidate.candidate.threshold;
            }
          }
        });
      }
    });

    wasteSanFran = Math.round(wasteSanFran / candidatesSanFran);
    wasteChicago = Math.round(wasteChicago / candidatesChicago);
    wasteMiami = Math.round(wasteMiami / candidatesMiami);

    const discCities = {
      sanFran: game.data.discriminatory[0],
      chicago: game.data.discriminatory[1],
      miami: game.data.discriminatory[2],
    };

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Average Candidate Overpayment
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='mt-3 row'>
          <div className='col-12'>
            <div className='bg-white'>
              <div className='col-6 offset-3 p-5'>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'bar',
                      height: 250,
                    },
                    title: {
                      text: '',
                    },
                    xAxis: {
                      categories: ['San Francisco', 'Chicago', 'Miami'],
                    },
                    yAxis: {
                      min: 0,
                      gridLineWidth: 0,
                      minorGridLineWidth: 0,
                      labels: {
                        enabled: false,
                      },
                      title: {
                        text: '',
                      },
                    },
                    legend: {
                      enabled: false,
                    },
                    tooltip: {
                      formatter: function () {
                        if (this.key === 'San Francisco')
                          return `# of Discriminatory Firms : ${discCities.sanFran}`;
                        if (this.key === 'Chicago')
                          return `# of Discriminatory Firms : ${discCities.chicago}`;
                        if (this.key === 'Miami')
                          return `# of Discriminatory Firms : ${discCities.miami}`;
                      },
                    },
                    plotOptions: {
                      series: {
                        stacking: 'normal',
                        dataLabels: {
                          enabled: true,
                          inside: true,
                          formatter: function () {
                            if (this.y === 0) {
                              return '';
                            } else {
                              return (
                                '$' +
                                Highcharts.numberFormat(this.y, 0, '.', ',')
                              );
                            }
                          },
                        },
                      },
                    },
                    responsive: {
                      rules: [],
                    },
                    series: [
                      {
                        name: 'San Francisco',
                        data: [wasteSanFran, 0, 0],
                      },
                      {
                        name: 'Chicago',
                        data: [0, wasteChicago, 0],
                      },
                      {
                        name: 'Decrease',
                        data: [0, 0, wasteMiami],
                      },
                    ],
                    credits: false,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DebriefWaste);
