import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Actions
import { gameActions } from '../actions';

class Reopen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    // Bindings
    this.toggleModal = this.toggleModal.bind(this);
    this.openGroup = this.openGroup.bind(this);
  }

  //   componentDidMount() {}

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  openGroup() {
    const { dispatch, game, group } = this.props;
    dispatch(gameActions.setGroups(game, [group]));
  }

  render() {
    return (
      <>
        <small
          className='btn btn-link btn-sm'
          style={{ fontSize: '.7rem', cursor: 'pointer' }}
          onClick={this.toggleModal}>
          (Reopen)
        </small>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          centered={true}>
          <ModalHeader>Reopen Group Negotiation</ModalHeader>
          <ModalBody className='text-center'>
            <p>You are about to reopen a closed negotiation.</p>
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-light' onClick={this.toggleModal}>
              Cancel
            </button>
            <button
              className='btn btn-outline-primary'
              onClick={this.openGroup}>
              Open
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {} = state;
  return {};
}

export default connect(mapStateToProps)(Reopen);
